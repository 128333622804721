import EditIcon from '@mui/icons-material/Edit'
import { Avatar, Button, Typography } from '@mui/material'
import { useGetImage, useGetUser } from 'api/hooks/get'
import { usePostImage } from 'api/hooks/post'
import COLORS from 'assets/colors'
import Flex from 'components/common/Flex'
import { useModal } from 'contexts/ModalContext'
import { useAuth } from 'hooks/useAuth'
import { calculateJoiningTime } from 'utils'
import EditProfile from './EditProfile'

const ProfilePage = () => {
  const { user } = useAuth()
  const { showModal } = useModal()
  const fetchUser = useGetUser()
  const { first_name, last_name, created_at } = fetchUser.data.id ? fetchUser.data : user
  const { mutate: postImage } = usePostImage()
  const fetchImage = useGetImage(true, 'PROFILE', user?.id)
  const imageUrl = fetchImage?.data?.url

  const handleUploadPhoto = async () => {
    document.getElementById('fileInput').click()
  }

  const handleFileChange = async (e) => {
    const file = e.target.files[0]
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      await postImage({
        type: 'PROFILE',
        linked_id: user.id,
        file,
        fileType: file.type,
      })
    }
  }

  return (
    <Flex sx={style.container}>
      <Flex position="relative">
        <Avatar
          src={imageUrl}
          sx={{
            width: 180,
            height: 180,
            backgroundColor: COLORS.white,
            border: `1px solid ${COLORS.black30}`,
          }}
        />
        <Button
          onClick={handleUploadPhoto}
          sx={{
            position: 'absolute',
            top: '8px',
            right: '-16px',
            minWidth: 'auto',
            padding: '4px 8px',
            backgroundColor: COLORS.black0,
            border: `1px solid ${COLORS.black30}`,
            color: COLORS.black70,
            fontSize: '14px',
            '&:hover': {
              backgroundColor: COLORS.black20,
            },
          }}
        >
          Add photo
          <input
            type="file"
            id="fileInput"
            accept="image/png, image/jpeg"
            hidden
            onChange={(e) => handleFileChange(e)}
          />
        </Button>
      </Flex>
      <Flex gap="4px" position="relative" alignItems="center">
        <Typography variant="h5" fontWeight="bold">
          {`${first_name} ${last_name}`}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {calculateJoiningTime(created_at)}
        </Typography>
        <EditIcon sx={style.editIcon} onClick={() => showModal(<EditProfile />)} />
      </Flex>
    </Flex>
  )
}

export default ProfilePage

const style = {
  container: {
    width: '100%',
    m: '64px auto',
    alignItems: 'center',
    gap: '16px',
  },
  editIcon: {
    position: 'absolute',
    top: '-16px',
    right: '-24px',
    fontSize: '20px',
    cursor: 'pointer',
    color: 'primary.main',
    '&:hover': { opacity: '80%' },
  },
}
