import { Avatar, Button, Divider, Menu, MenuItem, Typography } from '@mui/material/'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetImage } from '../api/hooks/get'
import { useAuth } from '../hooks/useAuth'
import Flex from './common/Flex'

const pages = ['Create', 'Home']

const Header = () => {
  const [anchorElUser, setAnchorElUser] = useState(null)

  const { user, logout } = useAuth()
  const navigate = useNavigate()
  const fetchImage = useGetImage(true, 'PROFILE', user?.id)
  const imageUrl = fetchImage?.data?.url

  const handleOpenUserMenu = (event) => {
    event.preventDefault()
    setAnchorElUser(event.currentTarget)
  }

  // handle main nav bar menu
  const handleCloseNavMenu = (item) => {
    if (typeof item !== 'object') {
      switch (item) {
        case 'Home':
          navigate('/events')
          break
        case 'Create':
          navigate('/events/create')
          break
        default:
          navigate('/' + item)
      }
    }
  }

  const handleCloseUserMenu = (item) => {
    // clickevent outside the menu area results in an object rather than a string
    if (typeof item !== 'object') {
      setAnchorElUser(null)
      switch (item) {
        case 'Logout':
          logout()
          break
        default:
          navigate('/' + item.toLowerCase())
          break
      }
    } else {
      setAnchorElUser(null)
    }
  }

  return (
    <>
      <Flex backgroundColor="white" alignItems="center">
        <Flex
          row
          sx={{
            width: '100%',
            maxWidth: '1000px',
            padding: ['0 16px', '0 32px'],
            justifyContent: 'space-between',
            height: '64px',
          }}
        >
          <Typography
            variant="h5"
            letterSpacing=".2rem"
            noWrap
            onClick={() => navigate(!user ? '/' : '/events')}
            fontWeight="800"
            sx={{ cursor: 'pointer', color: 'primary.main' }}
          >
            GroupUp
          </Typography>

          <Flex row justifyContent="flex-end" alignItems="center" gap={['16px', '32px']}>
            {user &&
              pages.map((page) => (
                <Typography
                  key={page}
                  fontWeight="bold"
                  onClick={() => handleCloseNavMenu(page)}
                  sx={{
                    borderBottom: '2px solid transparent',
                    cursor: 'pointer',
                    '&:hover': { borderColor: 'primary.main' },
                    transition: 'border-color 0.2s',
                  }}
                  p="20px 0"
                  zIndex={1}
                >
                  {page}
                </Typography>
              ))}
            {user ? (
              <>
                <Avatar
                  src={imageUrl}
                  onClick={handleOpenUserMenu}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': { boxShadow: '0px 4px 8px rgba(0,0,0,.075)' },
                  }}
                />
                <Menu
                  sx={{ mt: '45px' }}
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {['Profile', 'Logout'].map((item) => (
                    <MenuItem key={item} onClick={() => handleCloseUserMenu(item)}>
                      <Typography textAlign="center">{item}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            ) : (
              <Button variant="text" onClick={() => navigate('/login')}>
                <Typography>Login</Typography>
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Divider />
    </>
  )
}

export default Header
