import { CardMedia, Typography } from '@mui/material'
import logo from 'assets/images/logo.png'
import Flex from 'components/common/Flex'
import { useNavigate } from 'react-router-dom'
import { formatDate } from 'utils'
import { useGetImage } from 'api/hooks/get'

const EventCard = ({ eventData, user_id }) => {
  const navigate = useNavigate()

  const { id, title, start_date_time, location, hostId } = eventData

  const isHost = user_id === hostId

  const fetchImage = useGetImage(true, 'EVENT', id)
  const imageUrl = fetchImage.data.url

  const handleEventClick = (e) => {
    e.preventDefault()
    navigate(`/events/${id}`)
  }

  return (
    <Flex
      card
      onClick={handleEventClick}
      row
      gap={['16px', '32px']}
      position="relative"
      alignItems="flex-start"
      sx={{ cursor: 'pointer' }}
    >
      <CardMedia
        component="img"
        src={imageUrl ?? logo}
        alt="logo"
        sx={{ maxHeight: ['80px', '100px'], maxWidth: ['80px', '100px'] }}
      />
      <Flex>
        <Typography variant="subtitle1" color="textSecondary" mb="4px">
          {formatDate(start_date_time, 'EventCard')}
        </Typography>
        <Typography variant="h5" mb="2px">
          {title}
        </Typography>
        <Typography color="textSecondary">{location}</Typography>
        {isHost && (
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{ position: 'absolute', bottom: 8, right: 16 }}
          >
            <i>hosting</i>
          </Typography>
        )}
      </Flex>
    </Flex>
  )
}

export default EventCard
