import {
  useGetAllEventRsvps,
  useGetComments,
  useGetEvent,
  useGetEventUsers,
  useGetImage,
  useGetPolls,
  useGetRegistry,
  useGetTasks,
  useGetTravel,
} from 'api/hooks/get'
import Loading from 'components/common/Loading'
import { useAuth } from 'hooks/useAuth'
import { createContext, useContext } from 'react'

const EventContext = createContext()

export const EventProvider = ({ children }) => {
  const { user } = useAuth()
  const fetchEvent = useGetEvent()

  // prefetch event data
  const fetchUsers = useGetEventUsers()
  const fetchRsvps = useGetAllEventRsvps()
  const fetchRegistry = useGetRegistry()
  const fetchComments = useGetComments()
  const fetchAttendeeIncompletedTasks = useGetTasks({ is_completed: false })
  const fetchAttendeeCompletedTasks = useGetTasks({ is_completed: true })
  const fetchPolls = useGetPolls()
  const fetchImage = useGetImage(true, 'EVENT', fetchEvent.data?.id)
  const fetchTravel = useGetTravel()


  if (
    fetchEvent.isLoading ||
    (user &&
      (fetchAttendeeIncompletedTasks.isLoading || //  ensures hide/show task tab logic is done before rendering
        fetchAttendeeCompletedTasks.isLoading ||
        fetchRegistry.isLoading))
  )
    return <Loading margin="32px" />

  const isHost = fetchEvent.data.hostId === user?.id

  return (
    <EventContext.Provider
      value={{
        fetchEvent,
        fetchRsvps,
        fetchRegistry,
        fetchComments,
        fetchAttendeeIncompletedTasks,
        fetchAttendeeCompletedTasks,
        fetchPolls,
        fetchUsers,
        fetchImage,
        fetchTravel,
        isHost,
      }}
    >
      {children}
    </EventContext.Provider>
  )
}

export const useEvent = () => {
  return useContext(EventContext)
}
